body {
    background-color: #f0f2f5 !important;
}

/*Util classes*/

.version {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0px;
    font-style: italic;
}

.env {
    font-weight: bold;
    display: inline-block;
    color: red;
    margin-bottom: 0px;
}

.height-100 {
    height: 100%;
}

.margin {
    margin: 1em;
}

.margin-bottom {
    margin-bottom: 1em;
}

.margin-top {
    margin-top: 1em;
}

.margin-left {
    margin-left: 1em;
}

.margin-right {
    margin-right: 1em;
}

.thin-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(160, 160, 160, 0.3);
    background-color: #f5f5f5;
}

.thin-scrollbar::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(160, 160, 160, 0.3);
}

.hidden {
    display: none !important;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.ant-modal-footer {
    background-color: white;
    background-color: white !important;
    border-top: 1px solid #f0f0f0 !important;
}

.ant-form-item-label {
    white-space: pre-wrap !important;
    overflow: visible !important;
}

.progress {
    //margin: 50px auto;
    //padding: 2px;
    width: 100%;
    max-width: 500px;
    background: #F0F0F3;
    border-radius: 20px;
    height: 5px;
    
  }

  .progress .progress__bar {
    height: 100%;
    width: 5%;
    border-radius: 15px;
   
    
  }
  .container_type_report {
    width: 300px;
    height: 100px;
    border-radius: 10px;
    background: white;
    padding: 10px;
    margin: 10px;

  }

  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -50px;
    bottom:50px;
    justify-content: center;
    background-color: rgb(242, 242, 242);
    padding: 1.5rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: 150px;
  }
  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }

  .popover__wrapper {
    position: relative;
    margin-top: 1.5rem;
    display: inline-block;
  }

  .ny-blink {
    animation: ny-blink-animation 1s steps(5, start) infinite;
    -webkit-animation: ny-blink-animation 1s steps(5, start) infinite;
}
@keyframes ny-blink-animation {
    to {
        visibility: hidden;
    }
}
